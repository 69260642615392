import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { any, result, values } from 'underscore';
import $ from 'jquery';
import 'jstree';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-intermediary-alignment',
  templateUrl: './intermediary-alignment.component.html',
  styleUrls: ['./intermediary-alignment.component.scss']
})
export class IntermediaryAlignmentComponent implements OnInit, OnDestroy {

  onInitialized = new EventEmitter<IntermediaryAlignmentComponent>();

  public selectedListForAlign: any;
  public selectedGradeBeanList: any;
  public label: any;
  public treeNodeAligned = false;
  isLeft = false;
  outerCount = 0;
  intermediaryOuterCount = 0;
  hasPartLength = 0;
  hasPartLoopLength = 0;
  innerCount = 0;
  intermediaryInnerCount = 0;
  initialInterMediaryData: any;
  isChildSection = false;
  initialProductData: any;
  initialData: any;
  loading = false;
  productUrl: any;
  isexpandable = false;
  interMediaryTreeData: any;
  isDataFetchCompleted: any = true;
  productSubscriber: any;
  index = 0;
  isIntialLoading = true;
  childDataArray: any[] = [];
  childDataArrayProduct: any[] = [];
  noteListAdded: any;
  expandedProductDetails: any;
  expandTreeCount = 0;
  isLoopingCompleted = true;
  isSave = false;
  public intermediaryAlignData: any = {
    mappingType: any
  }
  public intermediaryAlignmentData: any = {
    gradeInfo: {
      selectedGrade: {},
      selectedStrand: {}
    }
  }
  public selectedTab1 = true;
  public curriculumTreeData: any;
  public newCartItemCount: any;
  public currentTabId: any = 'intermediary-tab-head';
  public selectedList: any = {
    subject: any
  }
  public intermediaryCodeSearchEnabled: any;
  public searchListItem: any = []
  public alignmentType = 'printDigital';
  treeHierarchyData: any;
  intermediarySearchList = {};
  initialProductDetails: any;
  selectedNodeData: any = {
    notesList: []
  };
  selectedTopicsList: any = [];
  lastAlignedNodeId: any;
  mappingExpandAllBtnLabel = 'EXPAND ALL';
  productMapping: any;
  productIRI: any;
  childIRI: any;
  disableCopy: boolean = false;
  disablePaste = false;
  disableStandardCopy = false;
  disableUnalign = false;
  disableStandardPaste = false;
  knowledgeCartList: any = [];
  knowledgeCartDirty: any;
  alignmentTypePrint: boolean = false;
  alignmentTypeDigi: boolean = false;
  alignmentTypePrintDigi: boolean = false;
  selectedTopics: any;
  enableUnalign: any = false;
  popupData: any = {
    confirmationMsg: any,
    popupMessage: any,
    buttonLabel: any
  };
  modalRef: any;
  copiedIntermediaries: any;
  enablePaste: boolean = false;
  public searchModel: any = {
    'type': [
      { schema: 'INTERMEDIARY_TYPE_STFK_SCHEMA', type: 'INTERMEDIARY_TYPE_STFK', value: 'Skills, Tools and Factual Knowledge', shortValue: "STFk", checked: true },
      { schema: 'INTERMEDIARY_TYPE_CU_SCHEMA', type: 'INTERMEDIARY_TYPE_CU', value: 'Conceptual Understanding', shortValue: "CU", checked: true },
      { schema: 'INTERMEDIARY_TYPE_P_SCHEMA', type: 'INTERMEDIARY_TYPE_P', value: 'Process', shortValue: "P", checked: true }
    ],
    'text': ''
  }
  productChildSubscriber: any;
  interMediarySubsciber: any;
  intermediaryChildSubscriber: any;
  saveMappingSubScriber: any;
  unAllignSubscriber: any;
  statementSubscriber: any;
  mappingSubscriber: any;
  getStatementSubscriber: any;

  constructor(
    private httpRestClient: HttpRestClientService,
    private preloadData: PreloadDataService,
    public globalVar: GlobalVariablesService,
    private confirmationPopup: ConfirmationPopupService,
    private modalService: BsModalService,
    private route: Router
  ) {
    // this.preloadData.componentOneFn =this.preloadData.refreshLearningObj;
    this.preloadData.componentOneFn = this.refreshLearningObjTreeForProduct;

    this.preloadData.treeAligned = this.treeNodeAligned;
    this.preloadData.cartCountFunction = this.updateCartCount;
    this.preloadData.cartCount.subscribe(value => {
      this.newCartItemCount = value;
    });
    this.preloadData.knowledgeCartList.subscribe(value => {
      this.updateKnowledgeCart(value);
    })
    this.preloadData.selectedTopics.subscribe(value => {
      this.selectedTopics = value;
      //this.updateKnowledgeCart();
    })
    this.preloadData.enableUnalign.subscribe(value => {
      this.enableUnalign = value;
      let isPaste: any = localStorage.getItem('enablePaste');
      this.enablePaste = isPaste == "true" ? true : false;
    })
  }

  ngOnInit(): void {
    var productIRIParent;
    this.newCartItemCount = 0;
    this.enableUnalign = false;
    this.knowledgeCartList = [];
    this.intermediaryAlignData.mappingType = 'standardMapping';
    this.preloadData.currentSelectedAlignData.subscribe(alignData => this.selectedListForAlign = alignData);
    this.preloadData.currentGradeList.subscribe(gradeList => this.selectedGradeBeanList = gradeList);
    this.intermediaryAlignmentData.gradeInfo.selectedGrade = this.selectedListForAlign.curGrade;
    this.preloadData.currentIntermediaryAlignData.subscribe(data => this.intermediaryAlignData = data);
    this.preloadData.productMapping.subscribe(data => {
      this.productMapping = data;
      localStorage.setItem('isProductMapping', this.productMapping)
    });
    this.displayMetadataInfo();
    if (this.productMapping) {
      this.preloadData.productIRIValue.subscribe(data => productIRIParent = data);
      localStorage.setItem('curriculumURIParent', JSON.stringify(productIRIParent));
      this.preloadData.curriculumURIParent.next(productIRIParent);
      this.refreshLearningObjTreeForProduct(this.childIRI, undefined, undefined);
    }
    else {
      this.preloadData.productIRIValue.subscribe(data => productIRIParent = data);
      localStorage.setItem('curriculumURIParent', JSON.stringify(productIRIParent))
      this.preloadData.curriculumURIParent.next(productIRIParent);
      this.intermediaryAlignmentData.gradeInfo.selectedStrand = this.intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeBeanList[0];
      this.refreshLearningObjTree(true);
    }

    console.log(this.intermediaryAlignData);
    this.mappingExpandAllBtnLabel = 'EXPAND ALL';
    let isPaste: any = localStorage.getItem('enablePaste');
    this.enablePaste = isPaste == "true" ? true : false;
    this.reset();
  }

  // updateKnowledgeCart() {
  //   this.selectedTopicsList.forEach((item: any) => {
  //     this.knowledgeCartList.push(item);
  //   })
  // }

  ngOnDestroy(): void {
    this.productChildSubscriber ? this.productChildSubscriber.unsubscribe() : '';
    this.productSubscriber ? this.productSubscriber.unsubscribe() : '';
    this.preloadData ? this.preloadData.unsbscibeData() : ''; // trigger the unsubscribe
    this.intermediaryChildSubscriber ? this.intermediaryChildSubscriber.unsubscribe() : '';
    this.interMediarySubsciber ? this.interMediarySubsciber.unsubscribe() : '';
    this.saveMappingSubScriber ? this.saveMappingSubScriber.unsubscribe() : '';
    this.unAllignSubscriber ? this.unAllignSubscriber.unsubscribe() : '';
    this.statementSubscriber ? this.statementSubscriber.unsubscribe() : '';
    this.mappingSubscriber ? this.mappingSubscriber.unsubscribe() : '';
    this.getStatementSubscriber ? this.getStatementSubscriber.unsubscribe() : '';
  }

  updateKnowledgeCart(value: any) {
    if (value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (this.knowledgeCartList.length > 0) {
          // && this.knowledgeCartList[i]?.id !== value[i]?.id
          let itemExistingInCart = this.knowledgeCartList.find((item: any) => {
            return item.id == value[i]?.id
          })
          if (!itemExistingInCart) {
            value[i].isNoteAdded = false;
            value[i].noteList = []
            this.knowledgeCartList.push(value[i]);
          }
        }
        else if (!this.knowledgeCartList?.length) {
          value[i].isNoteAdded = false;
          value[i].noteList = []
          this.knowledgeCartList.push(value[i]);
        }
      }
    }
  }



  updateCartCount(count: any) {
    this.newCartItemCount = count;
  }

  displayMetadataInfo() {
    if (!this.productMapping) {
      this.globalVar.intermediaryPreSelectedDropdown.isProduct = false;
      this.label = this.selectedListForAlign.subject.subjectDesc + ':' + this.selectedListForAlign.country + ':' + this.selectedListForAlign.curriculum?.curriculumTitle;
    }
    else {
      this.globalVar.intermediaryPreSelectedDropdown.isProduct = true;
      this.globalVar.intermediaryPreSelectedDropdown.selectedList = this.selectedListForAlign;
      this.label = this.selectedListForAlign.prdSubject?.subjectDesc + ' : ' + this.selectedListForAlign.program?.name + ' : ' + this.selectedListForAlign.product + ' : ' + 'Grade ' + this.selectedListForAlign.prdGrade;
    }
  }

  refreshLearningObjTreeForProduct(childIri: any, httpRestClient: any, preloadData: any) {
    var reqApi = 'api/plan';
    if (childIri) {
      this.preloadData = preloadData;
      // this.preloadData.updateCurriculumTreeData({});
      this.intermediaryAlignData.treeRequestData = {
        iri: childIri
      };
    } else {
      this.preloadData.productIRIValue.subscribe(data => this.productIRI = data)
      if (!this.productIRI) {
        this.productIRI = localStorage.getItem('productIRI');
      }
      var selectedGrade = this.intermediaryAlignmentData?.gradeInfo?.selectedGrade;
      this.intermediaryAlignData.treeRequestData = {
        iri: this.productIRI
      };
    }
    this.intermediaryAlignData.gradeInfo = {
      selectedLocalGrade: {}
    }
    // if (selectedGrade.gradeBeanList.length > 1) {
    //   this.intermediaryAlignData.gradeInfo.selectedLocalGrade = selectedGrade.gradeBeanList[0];
    //   this.intermediaryAlignData.treeRequestData.strandIRI = selectedGrade.gradeBeanList[0].gradeIRI;
    // }
    var url = reqApi + '?iri=' + this.intermediaryAlignData.treeRequestData.iri
    var url = url + '&mapping=' + true;
    this.productUrl = url;
    if (childIri != undefined) {
      var url = url + '&expand=partial';
    }
    this.productUrl = this.productUrl + '&expand=full';
    if (this.httpRestClient == undefined) { this.httpRestClient = httpRestClient };
    if (this.preloadData == undefined) { this.preloadData = preloadData };
    // this.loading = true;
    this.productSubscriber = this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.loading = false;
      this.preloadData.updateCurriculumTreeData(result);
      setTimeout(() => {
        this.curriculumTreeData = result;
        this.preloadData.updateCurriculumTreeData(this.curriculumTreeData);
        this.initialProductDetails = result
      })
      localStorage.setItem("productData", JSON.stringify(result));
      this.innerCount = 0;
      this.outerCount = 0;
      this.childDataArrayProduct = [];
      if (!this.isSave) {
        setTimeout(() => {
          this.reset();
        }, 2000)
      }
      else {
        this.isSave = false;
      }

      // subscriber.unsubscribe();
    }, (error) => {
      console.log(error);
    });
  }

  buildExpandedTreeDataProduct(index: any) {
    console.log(this.curriculumTreeData);
    if (this.initialProductDetails?.hasPart[index]) {
      this.childTreeDataMappingProduct(this.initialProductDetails.hasPart[index]);
    }
  }

  buildTreeDataWithChildNodeProduct(result: any) {
    result?.hasPart?.forEach((item: any, key: any) => {
      if (item.hasPart) {
        this.getChildNodeProduct(item);
      }
    })
    return result;
  }

  getChildNodeProduct(item: any) {
    let hasPartObject: any = []
    if (item.hasPart) {
      item?.hasPart?.sort((a: any, b: any) => a.order - b.order);
      item?.hasPart?.forEach((subItem: any, key: any) => {
        if (subItem?.hasPart) {
          let hasPartData = this.childDataArrayProduct.find((element: any) => {
            return element.curriculumId == subItem.id
          })
          if (hasPartData) {
            subItem.hasPart = hasPartData.hasPart.hasPart;
            this.getChildNodeProduct(subItem);
          }
        }
      });
    }
  }

  childTreeDataMappingProduct(item: any) {
    if (item?.hasPart) {
      item?.hasPart?.forEach((subItem: any, key: any) => {
        if (subItem.hasPart) {
          this.isDataFetchCompleted = false;
          this.getChildDataFromApiProduct(subItem.id, item, key);
        }
      });
    }
    if (this.isDataFetchCompleted) {
      this.index++;
      if (this.index !== this.initialProductDetails.hasPart.length) {
        this.buildExpandedTreeDataProduct(this.index)
      }
      else {
        this.loading = false;
        this.curriculumTreeData = this.buildTreeDataWithChildNodeProduct(this.initialProductDetails);
        this.expandedProductDetails = this.curriculumTreeData;
        this.preloadData.updateCurriculumTreeData(this.curriculumTreeData);
        this.expandTreeCount = 0;
        console.log("ooooo", this.curriculumTreeData);
        this.curriculumTreeData.hasPart.forEach((item: any) => {
          this.expandTree(item);
        })
      }
    }

  }

  expandTree(item: any) {
    this.expandTreeCount++;
    setTimeout(() => {
      this.mappingExpandAllBtnLabel = this.preloadData.expandAllFunction('EXPAND ALL', item.id);
      if (item.hasPart) {
        item.hasPart.forEach((subItem: any) => {
          this.expandTree(subItem);
        });
      }
    }, this.expandTreeCount > 500 ? 10000 : 3000);

  }

  getChildDataFromApiProduct(childIri: any, parent: any, index: any) {
    let reqApi = 'api/plan';
    if (childIri) {
      this.preloadData.updateCurriculumTreeData({});
      this.intermediaryAlignData.treeRequestData = {
        iri: childIri
      };
    } else {
      this.preloadData.productIRIValue.subscribe(data => this.productIRI = data)
      this.intermediaryAlignData.treeRequestData = {
        iri: this.productIRI
      };
    }
    this.intermediaryAlignData.gradeInfo = {
      selectedLocalGrade: {}
    }
    // if (selectedGrade.gradeBeanList.length > 1) {
    //   this.intermediaryAlignData.gradeInfo.selectedLocalGrade = selectedGrade.gradeBeanList[0];
    //   this.intermediaryAlignData.treeRequestData.strandIRI = selectedGrade.gradeBeanList[0].gradeIRI;
    // }
    let url = reqApi + '?iri=' + this.intermediaryAlignData.treeRequestData.iri
    url = url + '&mapping=' + true;
    if (childIri != undefined) {
      if (this.isChildSection) {
        url = url + '&expand=full';
      }
      else {
        url = url + '&expand=partial';
      }
    }
    this.outerCount++;
    this.productChildSubscriber = this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.innerCount++;
      let chilData = {
        "curriculumId": childIri,
        "hasPart": result
      }
      if (result?.alternateName || result?.hasPart) {
        this.innerCount == this.outerCount ? this.isDataFetchCompleted = true : this.isDataFetchCompleted = false;
        this.childDataArrayProduct.push(chilData);
        this.childTreeDataMappingProduct(result);
      }
      //this.displayResult(result);
    }, (error) => {
      console.log(error);
      var self = this;
      self.route.navigate(['intermediary/align/home']);
    });
  }


  changeGrade() {
    this.treeNodeAligned = false;
    this.knowledgeCartList = [];
    this.curriculumTreeData = undefined;
    this.loading = true;
    this.preloadData.cartCount.next(0);
    if (this.intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeBeanList?.length) {
      this.intermediaryAlignmentData.gradeInfo.selectedStrand = this.intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeBeanList[0];

    }
    this.refreshLearningObjTree(true);
  }

  changeStrand() {
    this.treeNodeAligned = false;
    this.knowledgeCartList = [];
    this.curriculumTreeData = undefined;
    this.loading = true;
    this.preloadData.cartCount.next(0);
    this.refreshLearningObjTree(true);
  }

  intermediarylistItemLength: any;
  refreshLearningObjTree(isCurriculum: any) {
    this.mappingExpandAllBtnLabel = 'EXPAND ALL';
    var reqApi = 'api/objective';
    var selectedGrade = this.intermediaryAlignmentData.gradeInfo.selectedGrade;
    this.intermediaryAlignData.treeRequestData = {
      iri: 'https://data.savvas.com/curriculum/loc-structure/' + this.selectedListForAlign.curriculum.curriculumId + encodeURIComponent("#this"),
      gradeMaxIRI: selectedGrade.gradeMaxIRI,
      gradeMinIRI: selectedGrade.gradeMinIRI,
      strandIRI: ''
    };
    this.intermediaryAlignData.gradeInfo = {
      selectedLocalGrade: {}
    }
    if (selectedGrade.gradeBeanList?.length > 1) {
      this.intermediaryAlignData.gradeInfo.selectedLocalGrade = selectedGrade.gradeBeanList[0];
      this.intermediaryAlignData.treeRequestData.strandIRI = this.intermediaryAlignmentData.gradeInfo.selectedStrand.gradeIRI;
    }
    var url = reqApi + '?iri=' + this.intermediaryAlignData.treeRequestData.iri
    var url = url + '&mapping=' + isCurriculum + '&gradeMaxIRI=' + encodeURIComponent(this.intermediaryAlignData.treeRequestData.gradeMaxIRI) + '&gradeMinIRI=' + encodeURIComponent(this.intermediaryAlignData.treeRequestData.gradeMinIRI)
    if (this.intermediaryAlignmentData.gradeInfo.selectedStrand.gradeIRI) {
      url = url + '&strandIRI=' + encodeURIComponent(this.intermediaryAlignmentData.gradeInfo.selectedStrand.gradeIRI)
    }
    console.log(url);
    this.curriculumTreeData = undefined;
    this.loading = true;
    this.interMediarySubsciber = this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      console.log(result, 'xxxxxxxxx');
      this.initialInterMediaryData = result;
      this.intermediaryInnerCount = 0;
      this.intermediaryOuterCount = 0;
      this.childDataArray = [];
      this.buildExpandedTreeData(result)
      this.preloadData.updateCurriculumTreeData(result);
      //this.displayResult(result);
      if (!this.isSave) {
        setTimeout(() => {
          this.reset();
        }, 2000)
      }
      else {
        this.isSave = false;
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  innerChildCount: any;
  buildExpandedTreeData(result: any) {
    console.log(this.curriculumTreeData);
    this.innerChildCount = 0
    result?.hasPart?.forEach((item: any, key: any) => {
      this.innerChildCount++;
      this.hasPartLength = (item?.hasPart?.length) ? item.hasPart.length : 0;
      this.hasPartLoopLength = 0;
      this.childTreeDataMapping(item);
      // setTimeout(() => {
      //   this.loading = false;
      //   this.curriculumTreeData = this.buildTreeDataWithChildNode(result);
      //   this.preloadData?.updateCurriculumTreeData(this.curriculumTreeData);
      // }, 10000)
    });
    // if(result?.hasPart?.length ==isItemWithOutHasPart)
    //   {}
  }

  buildTreeDataWithChildNode(result: any) {
    result?.hasPart?.forEach((item: any, key: any) => {
      if (item.hasPart) {
        this.getChildNode(item);
      }
    })
    return result;
  }

  getChildNode(item: any) {
    let hasPartObject: any = []
    if (item.hasPart) {
      item.hasPart.sort((a: any, b: any) => a.order - b.order);

      item?.hasPart?.forEach((subItem: any, key: any) => {
        if (subItem?.definition) {
          this.getChildNode(subItem);
        }
        else {
          let hasPartData = this.childDataArray.find((element: any) => {
            return element.curriculumId == subItem
          })
          hasPartObject.push(hasPartData?.hasPart);
        }
      });
      if (hasPartObject.length) {
        item.hasPart = hasPartObject;
        this.getChildNode(item);
      }
    }
  }

  childTreeDataMapping(item: any) {
    if (item.hasPart) {
      this.isLoopingCompleted = false;
      item?.hasPart?.forEach((subItem: any, key: any) => {
        if (key == item?.hasPart.length - 1) {
          this.isLoopingCompleted = true;
        }
        if (subItem?.definition) {
          this.childTreeDataMapping(subItem);
        }
        else {
          this.getChildDataFromApi(subItem, item, key)
        }
      });
    }
    else {
      if ((this.intermediaryOuterCount == this.intermediaryInnerCount) && (this.initialInterMediaryData.hasPart.length == this.innerChildCount) && (this.isLoopingCompleted)) {
        this.loading = false;
        this.curriculumTreeData = this.buildTreeDataWithChildNode(this.initialInterMediaryData);
        this.preloadData?.updateCurriculumTreeData(this.curriculumTreeData);
      }
    }
  }

  getChildDataFromApi(curriculumId: any, parent: any, index: any) {
    this.intermediaryOuterCount++;
    var reqApi = 'api/objective';
    var selectedGrade = this.intermediaryAlignmentData?.gradeInfo?.selectedGrade;
    let curricum = '';
    curricum = curriculumId;
    this.intermediaryAlignData.treeRequestData = {
      iri: 'https://data.savvas.com/curriculum/loc-structure/' + curricum + encodeURIComponent("#this"),
      gradeMaxIRI: selectedGrade.gradeMaxIRI,
      gradeMinIRI: selectedGrade.gradeMinIRI,
      strandIRI: ''
    };
    this.intermediaryAlignData.gradeInfo = {
      selectedGrade: selectedGrade,
      selectedLocalGrade: {}
    }
    if (selectedGrade.gradeBeanList?.length > 1) {
      this.intermediaryAlignData.gradeInfo.selectedLocalGrade = selectedGrade.gradeBeanList[0];
      this.intermediaryAlignData.treeRequestData.strandIRI = selectedGrade.gradeBeanList[0].gradeIRI;
    }
    let url = '';
    let LocUrl = reqApi + '?iri=' + curriculumId
    url = LocUrl + '&mapping=' + true + '&expand=partial';
    console.log(url);

    this.intermediaryChildSubscriber = this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result: any) => {
      this.intermediaryInnerCount++;
      let chilData = {
        "curriculumId": curriculumId,
        "hasPart": result
      }
      this.hasPartLoopLength = 0;
      this.childDataArray.push(chilData);
      this.childTreeDataMapping(result);

    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  public displayResult = (result: any) => {
    this.curriculumTreeData = [];
    for (var i = 0; i < result.hasPart.length; i++) {
      if (result.hasPart[i].definition) {
        this.curriculumTreeData.push(result.hasPart[i].definition);
      }
    }
    console.log(this.curriculumTreeData, 'curriculumTreeData');
  }

  public selectTab = (selectedTab: any) => {
    if (selectedTab === 'intermediary-tab-head') {
      this.selectedTab1 = true;
    }
    else {
      this.selectedTab1 = false;
    }
    // this.knowledgeCartList.forEach((item: any) => {
    //   //item.selected = true;
    //   //item.printDigitalAlignment = 'PRINT_DIGITAL';
    // })
    // this.alignmentType = 'printDigital';
    // this.newCartItemCount = 0;

  }

  public getLeftSideStyle = () => {
    var height = window.innerHeight - 185;
    let style = { "min-height": height + "px", "max-height": height + "px", "overflow-y": "auto" };
    return style;
  }

  public getRightSideStyle = () => {

    let style = {
      "height": (window.innerHeight - 140) + "px",
      "margin-left": "10px"
    };
    return style;
  }

  public searchIntermediary = () => {

  }

  public clearSearchedTexts = () => {

  }

  public loadMore = () => {

  }

  public getIntermediaryTypeName = (typeArray: any) => {
    if (typeArray !== undefined) {
      var name;
      var type;
      var typeArrlength = typeArray.length;
      for (let i = 0; i < typeArrlength; i++) {
        type = typeArray[i];
        let applicationConstants = this.globalVar.applicationConstants;
        for (var key in applicationConstants) {
          if (applicationConstants.hasOwnProperty(key)) {
            for (var j = 0; j < this.searchModel.type.length; j++) {
              /////// Check area for possible errors f
              if (this.searchModel.type[j].type === key && type === applicationConstants[key]) {
                name = this.searchModel.type[j].shortValue;
                break;
              }
            }

          }
        }
      }
      return name;
    } else {
      return '';
    }
  }

  removeIntermediary() {
    if (this.knowledgeCartList.length === 0) {
      this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
    }
    else {
      console.log(this.preloadData.selectedTopicsList);
      for (let i = 0; i < this.knowledgeCartList.length; i++) {
        if (this.knowledgeCartList[i].selected === true) {
          // const index = this.knowledgeCartList.indexOf(i, 0);
          this.newCartItemCount--;
          const data = {
            data: this.knowledgeCartList[i],
            count: this.newCartItemCount
          }
          this.preloadData.removeData.next(data);
          this.knowledgeCartList.splice(i, 1);
          break;
        }
      }
      let slectedItem = this.knowledgeCartList.find((item: any) => { return item.selected })
      if (slectedItem) {
        this.removeIntermediary();
      }
    }
  };

  applyAssociations(predicate: any) {
    if (this.knowledgeCartList.length === 0) {
      this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
    }
    else {
      var knowledgeCartlength = this.knowledgeCartList.length;
      for (var i = 0; i < knowledgeCartlength; i++) {
        if (this.knowledgeCartList[i].selected) {
          if (this.knowledgeCartList[i].associationType !== predicate) {
            this.knowledgeCartList[i].associationType = predicate;
            this.knowledgeCartList[i].isAssociationDirty = true;
            if (!this.knowledgeCartDirty) {
              this.knowledgeCartDirty = true;
            }
          }
        }
      }
    }
  }

  //Apply align attribute such as Print/Digital 
  applyPrintDigitalAlignmentType = (predicate: string) => {
    var knowledgeCartlength = this.knowledgeCartList.length;
    for (var i = 0; i < knowledgeCartlength; i++) {
      if (this.knowledgeCartList[i].selected) {
        if (this.knowledgeCartList[i].printDigitalAlignment !== predicate) {
          this.knowledgeCartList[i].printDigitalAlignment = predicate;
        }
      }
    }
  };

  //Method to add dynamically alignment type background color
  getAlignmentMappingTypeColorClass = function (printDigitalAlignment: any) {
    var style;

    if (printDigitalAlignment !== undefined && printDigitalAlignment !== null && printDigitalAlignment !== "") {
      if (printDigitalAlignment === 'PRINT') {
        style = "print-P-icon";
      }
      else if (printDigitalAlignment === 'DIGITAL') {
        style = "print-D-icon";
      }
      else if (printDigitalAlignment === 'PRINT_DIGITAL') {
        style = "print-PD-icon";
      }
    }
    return style;
  };

  //Method to add dynamically association type background color
  getMappingTypeColorClass = function (associationType: any) {
    var style;
    if (associationType === 'P') {
      style = "relation-image-P-color";
    }
    else if (associationType === 'C') {
      style = "relation-image-C-color";
    }
    else if (associationType === 'K') {
      style = "relation-image-K-color";
    }
    return style;
  };

  //Method to add dynamically type background color
  getTypeBgColor = (typeArray: string) => {
    var style = '';
    var type = this.getIntermediaryTypeName(typeArray);
    if (type === 'STFk') {
      style = "intermediary-stfK-type";
    } else if (type === 'P') {
      style = "intermediary-P-type";
    } else if (type === 'CU') {
      style = "intermediary-C-type";
    }
    return style;
  };

  isknowledgeCartIntermediarySelected() {
    let length = 0;
    let index = 0;
    this.knowledgeCartList.forEach((data: any, key: any) => {
      data.selected ? length = length + 1 : length = length;
      data.selected ? index = key : index = index;
    })
    if (length == 0) {
      this.confirmationPopup.showErrorMessage('Please select one mapping to add notes to it');
    }
    if (length > 1) {
      this.confirmationPopup.showErrorMessage('Please select only one mapping to add notes to it');
    }
    if (length == 1) {

      let isPendingNote = false;
      this.knowledgeCartList[index].noteList.forEach((item: any) => {
        if (!item.isNoteDisable) {
          isPendingNote = true;
        }
      })

      // this.knowledgeCartList[index].noteList=[]
      let note = {
        'isNoteAdded': true,
        'isNoteDisable': false,
        'comment': "",
      }

      !isPendingNote ? this.knowledgeCartList[index].noteList.push(note) : '';
      !isPendingNote ? this.knowledgeCartList[index].showNote = true : ''
    }
  }

  selectAll() {
    if (this.knowledgeCartList.length === 0) {
      this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
    }
    else {
      this.knowledgeCartList.forEach((item: any) => {
        item.selected = true;
      })
    }

  }

  onCartItemChange(item: any, index: any) {
    console.log(item);
  }

  isknowledgeCartIntermediaryForSave() {
    let dataForNote: any = [];
    var parentURI: any;
    if (this.knowledgeCartList.length === 0) {
      this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
    } else if (this.knowledgeCartList.filter((item: any) => item.selected).length === 0) {
      this.confirmationPopup.showErrorMessage('Please select an intermediary statement(s)');
    }
    else {
      var reqApi = this.productMapping ? 'api/plan' : 'api/objective';
      var data = Object.assign({});
      data = {
        '@context': 'http://schema.savvas.com/context/changeset.jsonld',
        addition: [],
        removal: []
      }
      var additionObject = Object.assign({});
      var removalObject = Object.assign({});
      additionObject = {
        subject: any,
        predicate: 'centrallyRelated_printDigital',
        object: any
      }

      let initalCartValue = JSON.parse(this.initialData);

      for (let i = 0; i < this.knowledgeCartList.length; i++)  {
        if (this.knowledgeCartList[i].selected){
        additionObject = {
          subject: any,
          predicate: any,
          object: any
        }
        removalObject = {
          subject: any,
          predicate: any,
          object: any
        }
        var predicateValue = this.getPredicateValue(this.knowledgeCartList[i]);
        let cartItem = initalCartValue.find((item: any) => {
          return item.id == this.knowledgeCartList[i].id;
        });
        if (cartItem) {
          if (this.knowledgeCartList[i].associationType !== cartItem.associationType) {
            let removalPredicateValue = this.getPredicateValue(cartItem);
            removalObject.object = this.knowledgeCartList[i].id;
            removalObject.predicate = removalPredicateValue;
          }
        }
        additionObject.object = this.knowledgeCartList[i].id;
        additionObject.predicate = predicateValue;
        for (let j = 0; j < this.preloadData.selectedTopicsList.length; j++) {
          additionObject.subject = this.preloadData.selectedTopicsList[j].id;
          if (cartItem) {
            removalObject.subject = this.preloadData.selectedTopicsList[j].id;
          }
          break;
        }
        data.addition.push(additionObject);
        cartItem ? data.removal.push(removalObject) : '';
        let isStatementAdded: any;
        this.knowledgeCartList[i].noteList.forEach((dataNote: any) => {
          isStatementAdded = dataNote.isNoteDisable && dataNote.isNoteAdded
          if (isStatementAdded) {
            let dataObj = {
              '@context': 'http://schema.savvas.com/context/statement.jsonld',
              "creator": {
                "name": this.globalVar?.userProfile?.userName
              },
              "comment": dataNote.comment,
              "object": additionObject.object,
              "predicate": "http://schema.savvas.com/ns/learn/centrallyRelated",
              "subject": additionObject.subject,
              "type": "Statement",

            }
            dataForNote.push(dataObj);
          }
        })
      }
      }
      const headers: any = data;
      // let  this.dataForSave.createdBy = this.globalVar?.userProfile?.userName;
      if (dataForNote.length) {
        localStorage.setItem('noteListForCard', JSON.stringify(dataForNote));
        this.statementSubscriber = this.httpRestClient.postForUrl('api/statement', dataForNote, undefined).subscribe((result) => {

        }, (error) => {
          console.log(error);
        });
      }
      this.mappingSubscriber = this.httpRestClient.patchForUrl(reqApi, headers, undefined).subscribe((result) => {
        console.log(result);
        this.confirmationPopup.showSuccessMessage('Mapping have been saved successfully');
        this.curriculumTreeData = undefined
        this.preloadData.curriculumURIParent.subscribe(data => parentURI = data);
        this.isSave = true;
        for (let item of this.knowledgeCartList) {
          item.selected = false;
        }

        if (this.productMapping) {
          this.refreshLearningObjTreeForProduct(parentURI, '', this.preloadData);
        }
        else {
          this.refreshLearningObjTree(true);
        }
        // setTimeout(() => {
        //   this.preloadData.refreshTreeAfterSave(this.curriculumTreeData);
        // }, 1000)
        //$('#learningObjectiveSelectionTree').jstree(true).refresh();
        //this.displayResult(result);
      }, (error) => {
        console.log(error);
      });
    }
  }

  getPredicateValue(item: any) {
    var predicate;
    if (item.associationType === 'C') {
      if (item.printDigitalAlignment === 'print') {
        predicate = 'centrallyRelated_print';
      }
      else if (item.printDigitalAlignment === 'digital') {
        predicate = 'centrallyRelated_digital';
      }
      else if (item.printDigitalAlignment === 'printDigital') {
        predicate = 'centrallyRelated_printDigital';
      }
    }
    else if (item.associationType === 'P') {
      if (item.printDigitalAlignment === 'print') {
        predicate = 'peripherallyRelated_print';
      }
      else if (item.printDigitalAlignment === 'digital') {
        predicate = 'peripherallyRelated_digital';
      }
      else if (item.printDigitalAlignment === 'printDigital') {
        predicate = 'peripherallyRelated_printDigital';
      }
    }
    else if (item.associationType === 'K') {
      if (item.printDigitalAlignment === 'print') {
        predicate = 'keyRelated_print';
      }
      else if (item.printDigitalAlignment === 'digital') {
        predicate = 'keyRelated_digital';
      }
      else if (item.printDigitalAlignment === 'printDigital') {
        predicate = 'keyRelated_printDigital';
      }
    }
    return predicate;
  }

  backToDefaultView() {
    this.selectedTab1 = true;
    this.treeNodeAligned = false;
    this.knowledgeCartList = [];
    this.preloadData.cartCount.next(0);
    this.preloadData.backTODefault.next(true);
    this.selectAllTreeNode('deselectAll')
  }

  reset() {
    this.backToDefaultView();
    this.selectAllTreeNode('deselectAll')
  }

  backToMainScreen() {
    var self = this;
    self.globalVar.activeMainMenu = 'align';
    let data: any = localStorage.getItem("selectedData");
    if (JSON.parse(data)) {
      let selectedData = JSON.parse(data);
      localStorage.removeItem("selectedData");
      localStorage.setItem("dataForBackToSelection", JSON.stringify(selectedData));
    }
    self.route.navigate(['intermediary/align/home']);

  }

  clearAll() {
    if (this.knowledgeCartList.length === 0) {
      this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
    }
    else {
      this.knowledgeCartList.forEach((item: any) => {
        item.selected = false;
      })
    }
  }

  public alignStandardExpand(mappingExpandAllBtnLabel: any) {
    if (mappingExpandAllBtnLabel === 'EXPAND ALL' && this.productMapping && !this.expandedProductDetails) {
      this.loading = true;
      this.buildExpandedTreeDataProduct(this.index);
    }
    else {
      this.mappingExpandAllBtnLabel = this.preloadData.expandAllFunction(mappingExpandAllBtnLabel, this.preloadData);
    }
    // else if (mappingExpandAllBtnLabel === 'COLLAPSE ALL') {
    //   $("#learningObjectiveSelectionTree").jstree('close_all');
    //   this.mappingExpandAllBtnLabel = 'EXPAND ALL';

    //   // $rootScope.applicationCache.languageString.resources.intermediaryAlignTab.mappingExpandAllBtnLabel = 'EXPAND ALL';
    // }
  }

  public selectAllTreeNode(action: any) {
    if (action === 'selectAll') {
      $("#learningObjectiveSelectionTree").jstree('open_all');
      setTimeout(() => {
        $("#learningObjectiveSelectionTree").jstree().check_all();
      })
      this.treeNodeAligned = true;
      this.isexpandable = true;
      this.mappingExpandAllBtnLabel = 'COLLAPSE ALL';
    }
    else if (action === 'deselectAll') {
      $("#learningObjectiveSelectionTree").jstree().uncheck_all();
      $("#learningObjectiveSelectionTree").jstree('close_all');
      this.treeNodeAligned = false;
      this.isexpandable = false;
      this.mappingExpandAllBtnLabel = 'EXPAND ALL';
      this.preloadData.selectedTopics.next([]);
      this.preloadData.selectedTopicsList = [];
      var treeId = '#learningObjectiveSelectionTree';
      this.treeHierarchyData = $(treeId).jstree(true).get_json('#', { flat: true });
      var tempData = Object.assign(this.treeHierarchyData);
      this.preloadData.enableTreeFunction(treeId, tempData);
      this.preloadData.enableUnalign.next(false);
    }
  }

  public alignStandard = () => {
    this.treeNodeAligned = false;
    this.intermediaryAlignData
    if (!JSON.parse(this.intermediaryAlignData.lockedForAlignment === undefined ? 'false' : this.intermediaryAlignData.lockedForAlignment)) {
      if (this.preloadData.selectedTopicsList.length === 0) {
        this.confirmationPopup.showErrorMessage('Please select a standard to proceed');
      } else if (this.preloadData.selectedTopicsList.length > 1) {
        this.confirmationPopup.showErrorMessage('Please select only one standard at a time to map');
      }
      else {

        if (!this.treeNodeAligned) {
          this.knowledgeCartList = [];
          //update knowledge cart
          this.preloadData.selectedTopicsList.forEach((item: any) => {
            if (item.data?.centrallyRelated.length > 0 && Array.isArray(item.data?.centrallyRelated)) {
              item.data?.centrallyRelated?.forEach((int: any) => {
                int.associationType = 'C';
                int.isNoteAdded = false;
                int.noteList = []
                int.newItemAdded = true;
                this.knowledgeCartList.push(int);
              })
            }
          })
          this.preloadData.selectedTopicsList.forEach((item: any) => {
            if (item.data?.peripherallyRelated.length > 0 && Array.isArray(item.data?.peripherallyRelated)) {
              item.data?.peripherallyRelated?.forEach((int: any) => {
                item.associationType = 'P';
                int.associationType = 'P';
                int.isNoteAdded = false;
                int.noteList = [];
                int.newItemAdded = true;
                this.knowledgeCartList.push(int);
              })
            }
          })
          this.preloadData.selectedTopicsList.forEach((item: any) => {
            if (item.data?.keyRelated.length > 0 && Array.isArray(item.data?.keyRelated)) {
              item.data?.keyRelated?.forEach((int: any) => {
                item.associationType = 'K';
                int.associationType = 'K';
                int.isNoteAdded = false;
                int.noteList = [];
                int.newItemAdded = true;
                this.knowledgeCartList.push(int);
              })
            }
          })
          var treeId = '#learningObjectiveSelectionTree';
          this.treeNodeAligned = true;
          this.preloadData.treeAligned = true;
          this.treeHierarchyData = $(treeId).jstree(true).get_json('#', { flat: true });
          this.intermediarySearchList = {};
          this.selectedNodeData = this.preloadData.selectedTopicsList[0];
          var tempData = Object.assign(this.treeHierarchyData);
          this.disableTree(treeId, tempData);
          this.preloadData.disableTreeFunction(treeId, tempData);
          this.selectedNodeData.notesList = [];
          //get notes list and add in the cart
          this.getAvailableNotesData(this.selectedNodeData.id);

          var lastAlignedNodeId = $('.jstree-anchor.jstree-checked')[0].id;
          this.lastAlignedNodeId = Object.assign({}, lastAlignedNodeId);
          if ($(".hideBtnFromDesk").css("display") === "inline-block") {
            $(".ui-splitbar-icon.ui-splitbar-icon-right").click();
          }
          var url = 'api/statement?subjectIRI=' + this.preloadData.selectedTopicsList[0]?.id;
          this.getStatementSubscriber = this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
            let selectedNoteList = result;
            if (this.knowledgeCartList.length) {
              this.knowledgeCartList.forEach((cartItem: any) => {
                selectedNoteList?.item?.forEach((noteItem: any) => {
                  if (cartItem.id == noteItem.object) {
                    noteItem.isNoteDisable = true;
                    cartItem.newItemAdded = false;
                    cartItem.noteList.push(noteItem);
                  }
                })
              })
              console.log("cartwithnote", this.knowledgeCartList);
            }
          });
        }
      }
    }
    this.preloadData.intialItemAddedToCard.next(this.knowledgeCartList);
    this.initialData = JSON.stringify(this.knowledgeCartList)
  };

  // public alignStandardExpand = (event: any) => {
  //   //var expand = obj.target.attributes['innerHtml'].value;

  //   if (event === 'EXPAND ALL') {
  //     $("#learningObjectiveSelectionTree").jstree("open_all");
  //     this.mappingExpandAllBtnLabel = 'COLLAPSE ALL';
  //   }
  //   else if (event === 'COLLAPSE ALL') {
  //     $("#learningObjectiveSelectionTree").jstree("close_all");

  //     this.mappingExpandAllBtnLabel = 'EXPAND ALL';
  //   }
  // };
  public enableTree = (treeId: any, tempData: any) => {
    $(treeId).jstree('enable_node', tempData);
    $(treeId + ' li a').removeClass('intermediary-disabled-tree-node');
    $(treeId + ' .jstree-checked').addClass('intermediary-disabled-tree-node').removeClass('intermediary-selected-tree-node');
    this.disableCopy = false;
    this.disablePaste = false;
    this.disableStandardCopy = false;
    this.disableUnalign = false;
    this.disableStandardPaste = false;
  }

  public disableTree = (treeId: any, tempData: any) => {
    $(treeId).jstree('disable_node', tempData);
    $(treeId + ' li a').addClass('intermediary-disabled-tree-node');
    $(treeId + '.jstree-checked').removeClass('intermediary-disabled-tree-node').addClass('intermediary-selected-tree-node');
    this.disableCopy = true;
    this.disablePaste = true;
    this.disableStandardCopy = true;
    this.disableUnalign = true;
    this.disableStandardPaste = true;
  }

  public unalign = (template: any) => {
    this.popupData.confirmationMsg = "Unaligned successfully";
    this.popupData.popupMessage = "Are you sure you want to unalign selected intermediaries?";
    this.popupData.buttonLabel = "Yes";
    this.modalRef = this.modalService.show(template);
  }

  public copyAlignments = () => {

    if (this.preloadData.selectedTopicsList.length > 1) {
      this.confirmationPopup.showErrorMessage('Please select only one standard at a time to map.')
    }
    else {
      this.confirmationPopup.showSuccessMessage('Alignments are successfully copied');
      this.knowledgeCartList = [];
      this.preloadData.selectedTopicsList.forEach((item: any) => {
        if (item.data?.centrallyRelated.length > 0 && Array.isArray(item.data?.centrallyRelated)) {
          item.data?.centrallyRelated?.forEach((int: any) => {
            int.associationType = 'C';
            int.isNoteAdded = false;
            int.noteList = []
            this.knowledgeCartList.push(int);
          })
        }
      })
      this.preloadData.selectedTopicsList.forEach((item: any) => {
        if (item.data?.peripherallyRelated.length > 0 && Array.isArray(item.data?.peripherallyRelated)) {
          item.data?.peripherallyRelated?.forEach((int: any) => {
            int.associationType = 'P';
            int.isNoteAdded = false;
            int.noteList = []
            this.knowledgeCartList.push(int);
          })
        }
      })
      this.preloadData.selectedTopicsList.forEach((item: any) => {
        if (item.data?.keyRelated.length > 0 && Array.isArray(item.data?.keyRelated)) {
          item.data?.keyRelated?.forEach((int: any) => {
            int.associationType = 'K';
            int.isNoteAdded = false;
            int.noteList = []
            this.knowledgeCartList.push(int);
          })
        }
      })
      this.preloadData.copiedIntermediaries.next(this.knowledgeCartList);
      localStorage.setItem('knowledgeCartList', JSON.stringify(this.knowledgeCartList));
      // localStorage.setItem('preloadData', JSON.stringify(this.preloadData));
      localStorage.setItem('curriculumTreeData', JSON.stringify(this.curriculumTreeData));
      this.selectAllTreeNode('deselectAll');
      this.enablePaste = true;
      localStorage.setItem('enablePaste', JSON.stringify(this.enablePaste));
      this.enableUnalign = false;
    }

  }

  public pasteAlignment = (template: any) => {
    if (this.preloadData.selectedTopicsList.length > 1) {
      this.popupData.popupMessage = "You have selected more than one node to paste alignments. Do you want to proceed";
      this.popupData.buttonLabel = "Yes";
      this.modalRef = this.modalService.show(template);
    }
    else {
      this.confirmPasteAlignment();
    }
  }

  public confirmPasteAlignment = () => {
    var parentURI: any;
    this.preloadData.curriculumURIParent.subscribe(data => parentURI = data);
    if (!parentURI) {
      localStorage.getItem('curriculumURIParent');
      parentURI = JSON.parse(parentURI);
    }
    var reqApi = 'api/plan';
    var data = Object.assign({});
    data = {
      '@context': 'http://schema.savvas.com/context/changeset.jsonld',
      addition: [],
      removal: []
    }
    var additionObject = Object.assign({});
    additionObject = {
      subject: any,
      predicate: any,
      object: any
    }
    this.preloadData.copiedIntermediaries.subscribe(data => this.copiedIntermediaries = data);
    let cartData: any = localStorage.getItem('knowledgeCartList');
    this.knowledgeCartList = JSON.parse(cartData);
    for (let i = 0; i < this.knowledgeCartList.length; i++) {
      let predicateValue = this.getPredicateValue(this.knowledgeCartList[i]);
      for (let j = 0; j < this.preloadData.selectedTopicsList.length; j++) {
        additionObject.subject = this.preloadData.selectedTopicsList[j].id;
        data.addition.push({
          subject: this.preloadData.selectedTopicsList[j].id,
          predicate: predicateValue,
          object: this.knowledgeCartList[i].id
        });
      }
    }
    //tempArray.push(additionObject);
    const headers: any = data;
    this.saveMappingSubScriber = this.httpRestClient.patchForUrl(reqApi, headers, undefined).subscribe((result) => {
      console.log(result);
      this.confirmationPopup.showSuccessMessage('Mapping have been saved successfully');
      this.preloadData.curriculumURIParent.subscribe(data => parentURI = data);
      if (!parentURI) {
        localStorage.getItem('curriculumURIParent');
        parentURI = JSON.parse(parentURI);
      }
      this.productMapping = localStorage.getItem('isProductMapping');
      // let preloadData:any = localStorage.getItem('preloadData');
      // this.preloadData = JSON.parse(preloadData);
      if (this.productMapping != 'false') {
        this.productMapping = true;
        setTimeout(() => {
          this.refreshLearningObjTreeForProduct(undefined, '', this.preloadData);
        }, 1000)
      }
      else {
        this.productMapping = false;
        this.refreshLearningObjTree(true);
      }
      this.enablePaste = false;
      localStorage.setItem('enablePaste', JSON.stringify(this.enablePaste));
      setTimeout(() => {
        let treeData: any = localStorage.getItem('curriculumTreeData');
        this.curriculumTreeData = JSON.parse(treeData);
        this.preloadData.refreshTreeAfterSave(this.curriculumTreeData);
      }, 1000)
      this.selectAllTreeNode('deselectAll');
      this.enableUnalign = true;
      //$('#learningObjectiveSelectionTree').jstree(true).refresh();
      //this.displayResult(result);
    }, (error) => {
      console.log(error);
    });

  }

  public confirmUnalign = () => {
    console.log("unalign confirmed")
    //var url = "https://cmtdev.savvas.com/cm-web/api/curriculum/loc-structure/3dc37867-56b1-4295-8129-4e90c1ba6625/loc/unalign";
    var parentURI: any;
    this.preloadData.curriculumURIParent.subscribe(data => parentURI = data);
    if (this.productMapping) {
      const myArray = parentURI.split("/");
      var uri = myArray[4];
    } else {
      var uri = parentURI;
    }
    var reqApi = 'api/curriculum/loc-structure/' + uri + '/loc/unalign';

    var data = Object.assign({});
    data = {
      nodeIds: []
    }
    // var additionObject = Object.assign({});
    for (let j = 0; j < this.preloadData.selectedTopicsList.length; j++) {
      // additionObject = this.preloadData.selectedTopicsList[j].id;
      data.nodeIds.push(this.preloadData.selectedTopicsList[j].id);

    }

    const headers: any = data;
    this.unAllignSubscriber = this.httpRestClient.patchForUrl(reqApi, headers, undefined).subscribe((result) => {
      console.log(result);
      this.confirmationPopup.showSuccessMessage('Intermediaries successfully unaligned');
      if (this.productMapping) {
        this.refreshLearningObjTreeForProduct(parentURI, '', this.preloadData);
      }
      else {
        this.refreshLearningObjTree(true);
      }

      setTimeout(() => {
        this.preloadData.refreshTreeAfterSave(this.curriculumTreeData);
      }, 1000)
      //$('#learningObjectiveSelectionTree').jstree(true).refresh();
      //this.displayResult(result);
      this.selectAllTreeNode('deselectAll');
    }, (error) => {
      console.log(error);
    });

  }

  public getAvailableNotesData = function (selectedNodeId: any) {

  }

  public showIntermediaryCode(code: any) {

  }

  public getCartRowHighlightClass(item: any) {

  }
  public showHideNotes(item: any) {
    item.showNote ? item.showNote = false : item.showNote = true;
  }

  public getCartStyle() {

  }
  // onLoad(selectedList: any) {
  //   this.selectedListForAlign = selectedList;
  //   console.log(this.selectedListForAlign);
  // }
  notes: any;
  addNote(item: any) {
    if (item.comment) {
      item.isNoteDisable = true;
    }
  }

  getDate() {
    return new Date();
  }

  expandLeft()
  {
    this.isLeft = true;
  }
}