<div class="loader-container" *ngIf="loading">
    <div class="loading-overlay"></div>
    <div class="loading-icon">
        <img src="assets/images/loading_100x100.gif" />
    </div>
</div>
<div class="intermediary-mapping-section" *ngIf="!loading">
    <div class="row-fluid mapping-title-label-container">
        <div class="span10">
            <label class="mapping-title-label" [textContent]="label"></label>
            <label *ngIf="!productMapping" for="standardMappingGradeId" class="mapping-title-label"
                [textContent]="' Grade'"></label>
            <select *ngIf="!productMapping" class="intermediary-grade-dropdown" id="standardMappingGradeId"
                [(ngModel)]="intermediaryAlignmentData.gradeInfo.selectedGrade" (change)="changeGrade()">
                <option value="" hidden selected>
                    {{intermediaryAlignmentData.gradeInfo.selectedGrade}}
                </option>
                <option *ngFor="let gradeObject of selectedGradeBeanList" [ngValue]="gradeObject">{{gradeObject.grade}}
                </option>
            </select>
            <label
                *ngIf="intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeUriList?.length > 1"
                for="standardMappingStrandGradeId" class="mapping-title-label"
                [textContent]="'Strand'"></label>
                <select *ngIf="intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeUriList?.length > 1" class="intermediary-grade-dropdown" id="standardMappingGradeId"
                [(ngModel)]="intermediaryAlignmentData.gradeInfo.selectedStrand"  (change)="changeStrand()">
                <!-- <option value="" hidden selected>
                    {{intermediaryAlignmentData.gradeInfo.selectedGrade}}
                </option> -->
                <option *ngFor="let gradeObject of intermediaryAlignmentData?.gradeInfo?.selectedGrade?.gradeBeanList" [ngValue]="gradeObject">{{gradeObject.gradeName}}
                </option>
            </select>
        </div>
        <div class="span2 intermediary-back">
            <img class="back-icon-margin" src="assets/images/mapping-back-icon.png"><a class="standard-back-label"
                [textContent]="'Back to Selection'" (click)="backToMainScreen()"></a>
        </div>
        <!-- <label class=" mapping-title-label content-node-name"
            data-ng-if="intermediaryAlignData.mappingType === 'productMapping' && treeNodeAligned"
            data-ng-bind-template="{{showSelectedTocLabel()}}"></label> -->
    </div>
    <!-- <div *ngIf="productMapping" style="text-align:center">
        <p>Objective : </p>
        <ul>
            <li *ngFor="let data of curriculumTreeData?.hasPart">
                {{data.name}}
            </li>
        </ul>
    </div> -->

    <div ui-layout="{flow : 'column', dividerSize :15}" class="layout-container-custom stretch ui-layout-column">
        <div ui-layout-container min-size="41%" max-size="60%" min-size="41%" size="35%" class="stretch ui-layout-container"
            style="width: 604px; left: 0px;" [ngClass]="{'left-width-expand': isLeft }">

            <ul id="tabs" class="tabs">
                <!-- <li data-ng-if="intermediaryAlignData.mappingType === 'productMapping'" data-ng-init="tabSelect('product-tab-head', 'product-data-tab')" id="product-tab-head" class="tab-link alignment-tabs-common product-tab-color" data-tab="product-data-tab"  data-ng-click="tabSelect('product-tab-head', 'product-data-tab')" data-ng-bind-template="{{applicationCache.languageString.resources.intermediaryAlignTab.mappingProductTabLabel | uppercase}}"></li> -->
                <li id="intermediary-tab-head" class="tab-link  alignment-tabs-common intermediary-tab-color current"
                    data-tab="intermediary-data-tab"
                    (click)="selectTab('intermediary-tab-head')" [textContent]="'INTERMEDIARY'">
                </li>
                <li id="cart-tab-head" class="tab-link alignment-tabs-common cart-tab-color current"
                    style="margin-left: 6px;" data-tab="cart-data-tab" (click)="selectTab('cart-tab-head')">
                    <span [textContent]="'CART'"></span>
                    <span class="cart-count-style"
                        *ngIf="currentTabId === 'intermediary-tab-head' && newCartItemCount > 0"
                        [textContent]="newCartItemCount"></span>
                </li>
            </ul>
            <div>
                <!-- Intermediary selection section -->
                <div [hidden]="selectedTab1==false" [ngStyle]="getLeftSideStyle()"
                    class="intermediary-tab-content intermediary-tab-color current">
                    <app-intermediary-statements></app-intermediary-statements>
                </div>
                <!-- knowledge cart section -->
                <div [hidden]="selectedTab1==true" id="cart-data-tab"
                    class="intermediary-tab-content cart-tab-color current">
                    <div class="cart-data-container">
                        <div class="cart-btn-container" (ngInit)="alignmentType='print'">
                            <div class="cmButton intermediary-tab-btn" [textContent]="'CENTRAL'"
                                (click)="applyAssociations('C')">
                            </div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'PERIPHERAL'"
                                (click)="applyAssociations('P')"></div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'KEY'"
                                (click)="applyAssociations('K')"></div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'NOTE'"
                                (click)="isknowledgeCartIntermediarySelected()"></div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'SELECT ALL'"
                                (click)="selectAll()">
                            </div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'REMOVE'"
                                (click)="removeIntermediary()"></div>
                            <div class="radioButton-alignment">
                                <input type="radio" class="radio-button-knowcart" [(ngModel)]="alignmentType"
                                    value="print" checked="alignmentType==='print'" data-ngValue="print"
                                    (change)="applyPrintDigitalAlignmentType('print')"><label>PRINT</label>
                            </div>
                            <div class="radioButton-alignment">
                                <input type="radio" class="radio-button-knowcart" [(ngModel)]="alignmentType"
                                    value="digital" checked="alignmentType==='digital'" data-ngValue="digital"
                                    (change)="applyPrintDigitalAlignmentType('digital')"><label>DIGITAL</label>
                            </div>
                            <div class="radioButton-alignment">
                                <input type="radio" id="primaryDefaultAlignmentType" class="radio-button-knowcart"
                                    [(ngModel)]="alignmentType" value="printDigital"
                                    checked="alignmentType==='printDigital'" data-ngValue="printDigital"
                                    (change)="applyPrintDigitalAlignmentType('printDigital')"><label>PRINT
                                    & DIGITAL</label>
                            </div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'SAVE'"
                                (click)="isknowledgeCartIntermediaryForSave()"></div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'DONE'"
                                (click)="backToDefaultView()">
                            </div>
                            <div class="cmButton intermediary-tab-btn" [textContent]="'CLEAR ALL'" (click)="clearAll()">
                            </div>
                        </div>
                        <div id="knowledgeCartId" class="cart-mapping-container">
                            <div class="knowledge-cart-list-container"
                                *ngFor="let item of knowledgeCartList;let index = index">
                                <div class="row-fluid"
                                    [ngClass]="{'cart-intermediaries-list': !item.isAssociationDirty,'cart-intermediaries-list-association': item.isAssociationDirty,'cart-row-central-highlight':item.associationType === 'C','cart-row-peripheral-highlight':item.associationType === 'P','cart-row-key-highlight':item.associationType === 'K'}">
                                    <div class="span2">
                                        <input class="knowledge-cart-input" (change)="onCartItemChange(item,index)"
                                            type="checkbox" [(ngModel)]="item.selected">
                                        <span *ngIf="item.associationType"
                                            class="intermediary-association-type-container"
                                            [ngClass]="{'relation-image-P-color':item.associationType === 'P','relation-image-C-color': item.associationType === 'C','relation-image-K-color':item.associationType === 'K'}">{{item.associationType}}</span>
                                        <span *ngIf="item.printDigitalAlignment"
                                            class="intermediary-alignment-type-container"
                                            [ngClass]="{'print-P-icon':item.printDigitalAlignment === 'print','print-D-icon': item.printDigitalAlignment === 'digital','print-PD-icon':item.printDigitalAlignment === 'printDigital'}">
                                        </span>
                                    </div>
                                    <span class="span7 knowledge-cart-text-container"><span>{{item.intermediaryStatementCode?.en}}
                                            - </span>{{item.prefLabel?.en}}</span>
                                    <div class="span7" style="margin-left: 370px">
                                        <img *ngIf="item.noteList?.length > 0 && !item.newItemAdded" [textContent]="'Show Notes'"
                                            class="cart-note-icon" src="assets/images/note_icon.png"
                                            (click)="showHideNotes(item)" />
                                        <span *ngIf="item.type" class="cart-type-container"
                                            [ngClass]="getTypeBgColor(item.type)">{{
                                            getIntermediaryTypeName(item.type) }}</span>
                                    </div>
                                </div>
                                <div class="mapping-notes-container" *ngIf = "item.showNote" >
                                    <p class="mapping-notes-text"></p>
                                    <label class="mapping-notes-date"></label>
                                 </div>
                                 <ng-container *ngIf = "item.showNote">
                                    <div  class="mapping-create-note-container"  *ngFor= "let note of item?.noteList; let i = index" style="margin-top: -36px; height:80px">
                                        <div style="position:relative">
                                            <textarea [(ngModel)]="note.comment"   style="width: 98%" [disabled]="note.isNoteDisable" class="mapping-create-note" >
                                            </textarea>
                                            <span *ngIf = "note.created" class="text-area-date">{{note.created}}</span>
                                            <span *ngIf = "note.isNoteDisable && !note.created" class="text-area-date">{{getDate()| date: 'yyyy/MM/dd'}}</span>
                                            <div *ngIf = "!note.isNoteDisable" class="pull-right cmButton intermediary-tab-btn"  (click)="addNote(note)" style="margin-right: 10px;">Add Note</div>
                                        </div>
                                     </div>
                                 </ng-container>
                                 
                            </div>
                           
                        </div>
                        <!-- <div class="cart-btn-container">
                                <div class="cmButton intermediary-tab-btn" [textContent]="'SAVE'"
                                    (click)="isknowledgeCartIntermediaryForSave()"></div>
                                <div class="cmButton intermediary-tab-btn" [textContent]="'DONE'" (click)="backToDefaultView()">
                                </div>
                                <div class="cmButton intermediary-tab-btn" [textContent]="'CLEARALL'" (click)="clearAll()">
                                </div>
                            </div> -->
                    </div>

                </div>

                <!-- <knowledgecart data-selected-data="selectedNodeData"></knowledgecart> -->
            </div>
        </div>
        <div ui-splitbar class="stretch ui-splitbar splitbar-column" style="width: 15px; left: 604px;" [ngClass]="{'splitbar-column-left': isLeft }">
            <a><span class="ui-splitbar-icon ui-splitbar-icon-left" (click) = "expandLeft()"></span></a>
            <a><span class="ui-splitbar-icon ui-splitbar-icon-right"></span></a>
        </div>
        <div ui-layout-container class="no-margin-left stretch" [ngStyle]="getRightSideStyle()"
            style="width: 870px; left: 619px; height: 239px; margin-left: 10px;" [ngClass]="{'displayNone': isLeft }">
            <div class="intermediary-standard-tree-container">
                <div class="standard-mapping-btn-container">
                    <span class="stdMappContFrMble">
                        <button class="intermediary-cm intermediary-btn hideBtnFromMble" [disabled]="treeNodeAligned"
                            title="Align" [textContent]="'ALIGN'" (click)="alignStandard()"></button>
                        <button class="intermediary-cm intermediary-btn hideBtnFromDesk" title="Align"
                            [textContent]="'ALIGN'" (click)="alignStandard()"></button>
                        <button class="intermediary-cm intermediary-btn" title="mappingExpandAllBtnLabel"
                            [innerHtml]="mappingExpandAllBtnLabel" [textContent]="mappingExpandAllBtnLabel"
                            (click)="alignStandardExpand(mappingExpandAllBtnLabel)" [disabled]="isexpandable"></button>
                        <button class="intermediary-cm intermediary-copy-btn" [disabled]="!enableUnalign"
                            title="CopyAlignments" [textContent]="'COPY ALIGNMENTS'"
                            (click)="copyAlignments()"></button>
                        <button class="intermediary-cm intermediary-btn" [disabled]="!enablePaste" title="Paste"
                            [textContent]="'PASTE'" (click)="pasteAlignment(pasteTemp)"></button>
                        <button *ngIf="this.selectedTopics.length > 0" class="intermediary-cm intermediary-btn"
                            title="Reset" [textContent]="'RESET'" (click)="reset()"></button>
                        <button class="intermediary-cm intermediary-btn" title="SelectAll" [textContent]="'SELECT ALL'"
                            (click)="selectAllTreeNode('selectAll')"></button>
                        <button class="intermediary-cm intermediary-btn" title="DeSelectAll"
                            [textContent]="'DESELECT ALL'" (click)="selectAllTreeNode('deselectAll')"></button>
                        <button class="intermediary-cm intermediary-btn" [disabled]="!enableUnalign" title="UnAlign"
                            [textContent]="'UNALIGN'" (click)="unalign(unalignPopupTemp)"></button>
                        <button class="intermediary-cm intermediary-btn" [disabled]="true" title="Apply"
                            [textContent]="'APPLY'" (click)="alignStandard()"></button>
                        <button class="intermediary-cm intermediary-btn" [disabled]="true" title="Cancel"
                            [textContent]="'CANCEL'" (click)="alignStandard()"></button>
                    </span>
                </div>

                <div *ngIf="curriculumTreeData" class="cmt-tree-container cmt-lo-tree-height">

                    <app-learning-objective-tree data="curriculumTreeData">
                    </app-learning-objective-tree>
                </div>
                <div *ngIf="!curriculumTreeData" class="cmt-tree-container cmt-lo-tree-height">

                    <span style="margin-left: 10px;">Loading...........</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #unalignPopupTemp>
    <app-warning-popup [DoFunctionOnSuccess]="confirmUnalign" [PopupMessage]="popupData.popupMessage"
        [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef">
    </app-warning-popup>
</ng-template>
<ng-template #pasteTemp>
    <app-warning-popup [DoFunctionOnSuccess]="confirmPasteAlignment" [PopupMessage]="popupData.popupMessage"
         [ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef">
    </app-warning-popup>
</ng-template>